import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core';
import Carousel from './Carousel';

const useStyles=makeStyles(()=>({
    banner:{
        height:400,
        backgroundImage:"url(./banner2.jpg)",
        backgroundPosition:'bottom',
        backgroundClip:'content-box',
    },
    bannerContent:{
        height:400,
        display:'flex',
        flexDirection:'column',
        paddingTop:25,
        justifyContent:'space-around',
    },
    tagline:{
        display:'flex',
        height:'40%',
        flexDirection:'column',
        justifyContent:'center',
        textAlign:'center',
    }
}));

const Banner = () => {
    const classes=useStyles();
  return (
    <div className={classes.banner}>
        <Container className={classes.bannerContent}>
            <div className={classes.tagline}>
            <Typography
                variant='h2'
                style={{
                    fontWeight:'bold',
                    marginBottom:15,
                    fontFamily:"Montserrat",
                }}
            >
                Crypto Tracker
            </Typography>
            <Typography
                variant='subtitle2'
                style={{
                    color:'darkgrey',
                    textTransform:'capitalize',
                    fontFamily:"Montserrat",
                }}
            >
                Stay tuned in this crypto world
            </Typography>
            </div>
            <Carousel />
        </Container>
    </div>
  )
}

export default Banner