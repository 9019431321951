import React from 'react'
import Banner from '../Components/Banner/Banner'
import Coinstable from '../Components/Coinstable'

const Homepage = () => {
  return <>
  <Banner />
  <Coinstable/>
  </>
}

export default Homepage