import { Container, createTheme, LinearProgress, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Classnames } from 'react-alice-carousel';
import { CoinList } from '../config/api';
import { CryptoState } from '../CryptoContext';
import {useNavigate } from 'react-router-dom';
import { numberWithCommas } from './Banner/Carousel';

const Coinstable = () => {
    const{currency,symbol} =CryptoState();
    const[coins,setCoins]=useState([]);
    const[page,setPage]=useState(1);
    const[loading,setLoading]=useState(false);
    const[search,setSearch]=useState("");
    const history = useNavigate();

    const fetchCoins=async ()=>{
        setLoading(true)
        const {data}=await axios.get(CoinList(currency))
        setCoins(data);
        setLoading(false);
    }

    console.log(coins);

    useEffect(()=>{
        fetchCoins();
    },[currency])

    const darkTheme=createTheme({
        palette:{
            primary:{
                main:"#fff",
            },
            type:'dark',
        },
    });

    const handleSearch=()=>{
        return coins.filter((coin)=>(
            coin.name.toLowerCase().includes(search) || 
            coin.symbol.toLowerCase().includes(search)
        ))
    }

    const useStyles=makeStyles(()=>({
        row:{
            backgroundColor:"#16171a",
            cursor:"pointer",
            "&:hover":{
                backgroundColor:'#131111',
            },
            fontFamily:"Montserrat"
        }
    }));

    const classes=useStyles();

  return (
    <ThemeProvider theme={darkTheme}>
        <Container style={{textAlign:'center'}}>
            <Typography
                variant='h4'
                style={{margin:18,fontFamily:'Montserrat'}}
            >
                Crypto Prices By Market Cap
            </Typography>
            <TextField 
                label="Search For a Crypto.." 
                variant='outlined'
                style={{marginBottom:20,width:"100%"}}
                onChange={(e)=>setSearch(e.target.value)}
            />
            <TableContainer>
                {loading?(
                        <LinearProgress style={{backgoundColor:"gold"}}/>
                    ) : (
                        <Table>
                            <TableHead style={{backgroundColor:"#EEBC1D"}}>
                                <TableRow>
                                    {["Coin","Price","24h Change"].map((head)=>(
                                        <TableCell
                                            style={{
                                                color:"black",
                                                fontWeight:"700",
                                                fontFamily:"Montserrat",
                                            }}
                                            key={head}
                                        >
                                            {head}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {handleSearch()
                                .slice((page-1)*10,(page-1)*10+10)
                                .map(row=>{
                                    const profit = row.price_change_percentage_24h>0;

                                    return (
                                        <TableRow onClick={()=>history.navigate('/coins/${row.id}')}
                                        className={classes.row}
                                        key={row.name}>
                                            <TableCell component='th' scope='row' styles={{display:'flex',gap:15}}>
                                                <img
                                                    src={row?.image}
                                                    alt={row?.name}
                                                    height="50"
                                                    style={{marginBottom:10}}

                                                />
                                                <div style={{display:'flex',flexDirection:'column'}}>
                                                <span
                                                    style={{
                                                        textTransform:'uppercase',
                                                        fontSize:22,
                                                    }}
                                                >
                                                    {row.symbol}
                                                </span>
                                                <span style={{color:"darkgrey"}}>{row.name}</span>
                                                </div>

                                            </TableCell>
                                            <TableCell>
                                                {numberWithCommas(row.current_price.toFixed(2))}
                                                {" "}{symbol}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    color:profit>0?"rgb(14,203,129)":"red",
                                                    fontWeight:500,
                                                }}
                                            >
                                                {profit&&"+"}
                                                {row.price_change_percentage_24h.toFixed(2)}%
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
            </TableContainer>
            <Pagination 
                count={(handleSearch()?.lenght/10).toFixed(0)}
                style={{
                    padding:20,
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                }}
                onChange={(_,value)=>{
                    setPage(value);
                    window.scroll(0,450);
                }}
            />
        </Container>
    </ThemeProvider>
  )
}

export default Coinstable